import "regenerator-runtime/runtime";

function customElement(elemName, classes, attributes) {
  classes = classes || [];
  attributes = attributes || {};
  var elem = document.createElement(elemName);

  for (var i = 0; i < classes.length; i++) {
    elem.classList.add(classes[i]);
  }

  for (var attribute in attributes) {
    elem.setAttribute(attribute, attributes[attribute]);
  }

  return elem;
}

function footer() {
  var sticyFooter = customElement("footer", ["sticky-footer", "bg-white"]);
  var container = customElement("div", ["container", "my-auto", "d-flex", "justify-content-between"]);
  var copyright = customElement("div", ["copyright", "text-center", "my-auto"]);
  var copyrightContainer = customElement("div");
  copyrightContainer.innerHTML = "Copyright &copy; European Commission 2022";
  var fileContainer = customElement("div");
  var fileLink = customElement("a", [], {
    href: "../copyright/ISA Product License v1 3.pdf",
    download: "ISA Product License v1 3.pdf"
  });
  fileLink.innerText = "ISA Product License v1.4";
  fileContainer.appendChild(fileLink);
  sticyFooter.appendChild(container);
  container.appendChild(copyright);
  container.appendChild(fileContainer);
  copyright.appendChild(copyrightContainer);
  document.getElementById("footer").appendChild(sticyFooter);
}

footer();

function sideNavBar() {
  var idToaddElements = "accordionSidebar";
  var unorderList = document.getElementById(idToaddElements);
  var sidebarBrand = customElement("a", ["sidebar-brand", "d-flex", "align-items-center"], {
    href: "../instructions.html"
  });
  unorderList.appendChild(sidebarBrand);
  var sidebarDiv = customElement("div", ["sidebar-brand-icon"]);
  sidebarBrand.appendChild(sidebarDiv);
  var faLandMark = customElement("i", ["fas", "fa-landmark"], {
    style: "padding-left: 20px;"
  });
  sidebarDiv.appendChild(faLandMark);
  var lowerCaseDiv = customElement("div", ["sidebar-brand-text", "text-lowercase"], {
    style: "font-size: 20px;padding-left: 13px"
  });
  var lowerCaseLabel = document.createTextNode("e");
  lowerCaseDiv.appendChild(lowerCaseLabel);
  sidebarBrand.appendChild(lowerCaseDiv);
  var capitalizeDiv = customElement("div", ["sidebar-brand-text", "text-capitalize"], {
    style: "font-size: 20px;"
  });
  var capitalizeLabel = document.createTextNode("GovERA");
  capitalizeDiv.appendChild(capitalizeLabel);
  sidebarBrand.appendChild(capitalizeDiv);
  var instructionsLi = customElement("li", ["nav-item"], {
    id: "nav-item-1"
  });
  var instructionsLink = customElement("a", ["nav-link"], {
    href: "../instructions.html"
  });
  instructionsLi.appendChild(instructionsLink);
  var instructionsIcon = customElement("i", ["fas", "fa-info-circle"]);
  instructionsLink.appendChild(instructionsIcon);
  var instructionsSpan = customElement("span");
  var instructionsSpanText = document.createTextNode("Overview & Guidelines");
  instructionsSpan.appendChild(instructionsSpanText);
  instructionsLink.appendChild(instructionsSpan);
  unorderList.appendChild(instructionsLi);
  var instructionsDivider = customElement("hr", ["sidebar-divider"]);
  unorderList.appendChild(instructionsDivider);
  var headingDiv = customElement("div", ["sidebar-heading"]);
  var headingText = document.createTextNode("Steps to follow");
  headingDiv.appendChild(headingText);
  unorderList.appendChild(headingDiv);
  var capabilityAssessmentLi = customElement("li", ["nav-item"], {
    id: "nav-item-2"
  });
  var capabilityAssessmentLink = customElement("a", ["nav-link"], {
    href: "../survey/survey.html"
  });
  var capabilityAssessmentIcon = customElement("i", ["fas", "fa-clipboard-list"]);
  var capabilityAssessmentSpan = customElement("span");
  var capabilityAssessmentSpanText = document.createTextNode("1. Capability assessment");
  capabilityAssessmentSpan.appendChild(capabilityAssessmentSpanText);
  capabilityAssessmentLi.appendChild(capabilityAssessmentLink);
  capabilityAssessmentLink.appendChild(capabilityAssessmentIcon);
  capabilityAssessmentLink.appendChild(capabilityAssessmentSpan);
  unorderList.appendChild(capabilityAssessmentLi);
  var decisionSupportLi = customElement("li", ["nav-item"], {
    id: "nav-item-3"
  });
  var decisionSupportLink = customElement("a", ["nav-link"], {
    href: "../roadmap/roadmaptest.html"
  });
  var decisionSupportIcon = customElement("i", ["fas", "fa-map"]);
  var decisionSupportSpan = customElement("span");
  var decisionSupportSpanText = document.createTextNode("2. Decision Support");
  decisionSupportSpan.appendChild(decisionSupportSpanText);
  decisionSupportLi.appendChild(decisionSupportLink);
  decisionSupportLink.appendChild(decisionSupportIcon);
  decisionSupportLink.appendChild(decisionSupportSpan);
  unorderList.appendChild(decisionSupportLi);

  if (sessionStorage.getItem("role") === "admin") {
    var backOfficeLi = customElement("li", ["nav-item"], {
      id: "nav-item-4"
    });
    var backOfficeLink = customElement("a", ["nav-link"], {
      href: "../backoffice/backOffice.html"
    });
    var backOfficeIcon = customElement("i", ["fas", "fa-user-shield"]);
    var backOfficeSpan = customElement("span");
    var backOfficeSpanText = document.createTextNode("3. Back Office");
    backOfficeSpan.appendChild(backOfficeSpanText);
    backOfficeLi.appendChild(backOfficeLink);
    backOfficeLink.appendChild(backOfficeIcon);
    backOfficeLink.appendChild(backOfficeSpan);
    unorderList.appendChild(backOfficeLi);
  }

  var backOfficeDiveder = customElement("hr", ["sidebar-divider"]);
  unorderList.appendChild(backOfficeDiveder);
  var togglerDiv = customElement("div", ["text-center", "d-none", "d-md-inline"]);
  var togglerBtn = customElement("button", ["rounded-circle", "border-0"], {
    id: "sidebarToggle",
    "data-target": "#accordionSidebar"
  });
  togglerDiv.appendChild(togglerBtn);
  unorderList.appendChild(togglerDiv);
  var url = location.href;
  var aTagsNodeList = unorderList.querySelectorAll("a[href]");

  var _loop = function _loop(i) {
    var element = aTagsNodeList[i];
    element.addEventListener("click", function () {
      sessionStorage.setItem("nav-link", element.parentElement.id);
    });
    var href = element.getAttribute("href");
    href = href.split("../")[1];

    if (url.indexOf(href) > -1) {
      if (href === "404.html") {
        if (element.parentElement.id === sessionStorage.getItem("nav-link")) element.parentElement.classList.add("active");
      } else element.parentElement.classList.add("active");
    } else {}
  };

  for (var i = 0; i < aTagsNodeList.length; i++) {
    _loop(i);
  }
}

sideNavBar();